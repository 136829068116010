import React from 'react';
import Img from 'gatsby-image';
import RevealFx from '../RevealFx';
import scrollMonitor from 'scrollmonitor';
import cn from 'classnames';

const instas = ['oscarmorke', 'jontgus', 'eriks'];
class FooterBoys extends React.Component {
  state = {
    instashow: false
  };

  setSizes = () => {
    const imagesElem = document.querySelector('.footerBoys-images');
    const container = document.querySelector('.footerBoys');
    const height = imagesElem.clientHeight;
    container.style.height = height + 'px';
  };

  componentDidMount() {
    this.setSizes();
    const titleRev = new RevealFx(this.title, {
      revealSettings: {
        bgcolor: '#1b1b1b',
        direction: 'rl',
        onCover: (contentEl, revealerEl) => {
          contentEl.style.opacity = 1;
        }
      }
    });
    window.addEventListener('resize', () => this.setSizes(), false);

    const watcher = scrollMonitor.create(this.title, 0);

    watcher.enterViewport(() => {
      titleRev.reveal();
      watcher.destroy();

      setTimeout(() => {
        this.setState({ insta: true });
      }, 900);
    });
  }
  render() {
    const images = this.props.images;

    return (
      <div className="footerBoys">
        <div className="footerBoys-content" ref={ref => (this.title = ref)}>
          <h2>Vi ser även ut som en digital agency</h2>
        </div>

        <div className="footerBoys-images">
          {images.map((edge, i) => (
            <div key={i} className="footerBoys-images-node">
              <a
                className={cn('insta', instas[i], {
                  'insta-show': this.state.insta
                })}
                rel="noopener noreferrer"
                href={`https://www.instagram.com/${instas[i]}`}
                target="_blank"
              >
                {instas[i]}
              </a>
              <Img alt="swosh" sizes={edge.node.childImageSharp.sizes} />
            </div>
          ))}
        </div>
      </div>
    );
  }
}

export default FooterBoys;
