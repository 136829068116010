import React from 'react';
import scrollMonitor from 'scrollmonitor';
import anime from 'animejs';

class About extends React.Component {
  // componentDidMount() {
  //   const animation = anime
  //     .timeline({ loop: false, autoplay: false })
  //     .add({
  //       targets: this.title,

  //       opacity: [0, 1],

  //       translateY: [15, 0]
  //     })
  //     .add({
  //       targets: this.content,
  //       opacity: [0, 1],
  //       translateY: [15, 0],
  //       offset: '-=300'
  //     });

  //   const watcher = scrollMonitor.create(this.title, -300);

  //   watcher.enterViewport(() => {
  //     animation.play();
  //     watcher.destroy();
  //   });
  // }

  render() {
    return (
      <div ref={ref => (this.container = ref)} id="about">
        <div className="about">
          <h2 ref={ref => (this.title = ref)}>
            Kreativt innehåll som sticker ut.
          </h2>
          <div ref={ref => (this.content = ref)} className="about-content">
            <div className="about-info">
              <p>
                Etablera är en liten kommunikationsbyrå som hjälper företag att
                synas, höras och växa. Vi hjälper din organisation att nå genom
                bruset och få snygga resultat.
              </p>
              <p>
                Kommunikation ”on-demand” — precis när du behöver det.
                Tillsammans formar vi ett vasst team som är effektivt, snabbt
                och lätt att jobba med. Vi älskar vad vi gör!
              </p>
              <br />
            </div>
            <div className="about-best">
              <p>
                <strong>Vi gillar:</strong>
                <br />
                🎬 Film
                <br />
                💑 Sociala medier
                <br />
                📲 Webb- och apputveckling.
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default About;
