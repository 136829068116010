import React from "react";
import cn from "classnames";
import isMobile from "ismobilejs";
import HeroText from "./HeroText";
import HeroVideo from "./HeroVideo";
import { IdleQueue } from "../idlize/IdleQueue";

class Hero extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      animated: false,
      loaded: false,
      showVideo: false,
      showText: false
    };
    this.queue = new IdleQueue();
  }

  componentDidMount() {
    this.queue.pushTask(() => {
      this.setState({
        showText: true
      });
    });
    this.queue.pushTask(() => {
      this.setState({
        showVideo: true
      });
    });

    this.queue.pushTask(() => {
      this.props.setAnimated();
    });
  }

  onLoaded = () => {
    this.setState({
      animated: true
    });
  };

  onVideoLoaded = () => {
    this.queue.pushTask(() => this.setState({ loaded: true }));
  };

  render() {
    const height = "100vh";
    const { videos } = this.props;

    let source = videos.find(
      item => item.node.name === "herovideo" && item.node.extension === "mp4"
    ).node.publicURL;

    if (isMobile.any) {
      source = videos.find(
        item => item.node.name === "mobile" && item.node.extension === "mp4"
      ).node.publicURL;
    }
    const showVideo = isMobile.any ? this.state.showText : this.state.loaded;
    return (
      <header className="hero" style={{ height }}>
        <div
          className={cn("hero-video", {
            "hero-video-show": showVideo
          })}
        >
          <div className="_pattern-overlay" />
          {this.state.showVideo && (
            <HeroVideo
              textAnimated={this.state.animated}
              source={source}
              onVideoLoaded={this.onVideoLoaded}
            />
          )}
        </div>
        {this.state.showText && <HeroText onLoaded={this.onLoaded} />}
        <div
          className={"hero-info " + (this.state.animated ? "info-show" : "")}
        >
          <a href="mailto:hej@etablera.co">hej@etablera.co</a>
          <a href="tel:+6586805812">+65 8680 5812</a>
        </div>
      </header>
    );
  }
}

export default Hero;
