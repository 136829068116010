/* eslint-disable */
import React from "react";
import { graphql } from "gatsby";
import Sections from "../components/Sections";
import Hero from "../components/Hero";
import Footer from "../components/Footer";
import Feedback from "../components/Feedback";
import About from "../components/About";
import SEO from "../components/SEO";
import InfoFooter from "../components/InfoFooter";
import VimeoGrid from "../components/VimeoGrid";
import FooterBoys from "../components/FooterBoys";
import Page from "../hoc/page";
import "../main.css";
import { IdleQueue } from "../idlize/IdleQueue";

export const query = graphql`
  query HomeQuery {
    site {
      siteMetadata {
        title
        description
        aboutText {
          body
        }
      }
    }

    ogImage: allFile(
      filter: { sourceInstanceName: { eq: "images" }, name: { eq: "ogimage" } }
    ) {
      edges {
        node {
          id
          name
          publicURL
        }
      }
    }

    footerImage: allFile(
      filter: {
        sourceInstanceName: { eq: "images" }
        name: { eq: "footer_new" }
      }
    ) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 1920, quality: 95) {
              ...GatsbyImageSharpSizes_withWebp
              aspectRatio
            }
          }
        }
      }
    }

    videos: allFile(filter: { sourceInstanceName: { eq: "video" } }) {
      edges {
        node {
          publicURL
          name
          extension
        }
      }
    }

    footerImages: allFile(filter: { sourceInstanceName: { eq: "footer" } }) {
      edges {
        node {
          childImageSharp {
            sizes(maxWidth: 650, quality: 90) {
              ...GatsbyImageSharpSizes_withWebp
              aspectRatio
            }
          }
        }
      }
    }

    allMarkdownRemark(sort: { fields: [frontmatter___date], order: DESC }) {
      edges {
        node {
          frontmatter {
            title
            info
            subinfo
            vimeo
          }
        }
      }
    }
  }
`;

class Index extends React.Component {
  state = {
    count: 1
  };

  setAnimated = () => {
    this.queue = new IdleQueue();

    for (let i = 0; i < 5; i++) {
      this.queue.pushTask(() => this.bump());
    }
  };

  bump = () => {
    this.setState(state => ({
      count: state.count + 1
    }));
  };

  render() {
    const { data } = this.props;
    const { count } = this.state;
    const ogImage = data.ogImage.edges[0].node.publicURL;
    const sections = data.allMarkdownRemark.edges;
    const { title, description } = data.site.siteMetadata;

    const parts = [
      <Hero
        setAnimated={this.setAnimated}
        key={1}
        videos={data.videos.edges}
      />,
      <About key={2} body={data.site.siteMetadata.aboutText.body} />,
      <Sections key={3} sections={sections} />,
      <Feedback key={4} />,
      <Footer key={5} image={data.footerImage.edges[0].node} />,
      <InfoFooter key={6} />
    ].filter((p, i) => i <= count);
    return (
      <div>
        <SEO title={title} description={description} image={ogImage} />
        {parts}
      </div>
    );
  }
}

export default Page(Index);
