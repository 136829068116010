import React from "react";
import RevealFx from "../RevealFx";
import scrollMonitor from "scrollmonitor";
import Player from "@vimeo/player";
import isMobile from "ismobilejs";
import debounce from "lodash/debounce";
import anime from "animejs";

class Project extends React.Component {
  constructor(props) {
    super(props);
    this.player = null;
  }

  videosSize = debounce(async () => {
    // return;
    const video = document.querySelector("#video-" + this.props.index);
    const width = video.clientWidth;

    const height = width * 0.5625;

    // const height = video.clientHeight;
    const target = document.querySelector("#content-" + this.props.index);

    target.style.height = height + "px";
  }, 200);

  setUpWatchers = () => {
    const splitContent = this.content;
    window.addEventListener("resize", () => this.videosSize(), false);

    // this.videosSize();
    const rev = new RevealFx(this.area, {
      revealSettings: {
        bgcolor: "#051a21",
        direction: "rl",
        duration: 400,
        easing: "easeInOutCirc",
        coverArea: 50,
        onCover: (contentEl, revealerEl) => {
          contentEl.style.opacity = 1;
          splitContent.classList.add("dual__content--show");
          this.videosSize();
        }
      }
    });

    const watcher = scrollMonitor.create(this.area, 400);
    const video = document.querySelector("#video-" + this.props.index);
    const width = video.clientWidth;

    const options = {
      id: this.props.node.frontmatter.vimeo,
      width: width,
      loop: true,
      playsinline: true
    };

    let animation;

    const start = () => {
      const player = new Player("video-" + this.props.index, options);
      this.player = player;
      player.setVolume(0);

      if (isMobile.any) {
        animation.play();
      }
    };

    if (isMobile.any) {
      animation = anime.timeline({ loop: false, autoplay: false }).add({
        targets: this.superInner,
        opacity: [0, 1],
        translateY: [5, 0]
      });
    } else {
      start();
    }

    watcher.enterViewport(() => {
      if (isMobile.any) {
        start();
      } else {
        this.videosSize();
        rev.reveal();
      }

      watcher.destroy();
    });
    this.videosSize();
  };

  componentDidMount() {
    setTimeout(() => {
      window.requestAnimationFrame(() => {
        this.setUpWatchers();
      });
    }, 300);
  }

  render() {
    const {
      node: { frontmatter }
    } = this.props;
    const { title, info, subinfo } = frontmatter;

    return (
      <section className="project" id="projects">
        <div className="dual">
          <div className="dual__inner" ref={ref => (this.area = ref)}>
            <div className="dual__half project-teaser">
              <div id={"video-" + this.props.index} />
            </div>
          </div>
          <div
            className="dual__content"
            id={"content-" + this.props.index}
            ref={ref => (this.content = ref)}
          >
            <div
              ref={ref => (this.superInner = ref)}
              className="dual-superInner"
            >
              <h3 ref={ref => (this.title = ref)}>{title}</h3>
              <div ref={ref => (this.content = ref)}>
                <p>{info}</p>
                <br />
                <p>{subinfo}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    );
  }
}

export default Project;
