import React from 'react';

class VimeoItem extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="vimeo-item">
        <div className="vimeo-wrap">
          <iframe
            src={
              'https://player.vimeo.com/video/' +
              this.props.vnum +
              '?portrait=0'
            }
            width="640"
            height="360"
            frameBorder="0"
            allowFullScreen={true}
          />
        </div>
      </div>
    );
  }
}

export default VimeoItem;
