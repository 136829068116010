import Helmet from "react-helmet";
import React from "react";
import favicon from "./favicons/main.png";
import faviconTwo from "./favicons/faviconFire.png";
import star from "./favicons/star.png";
import bulb from "./favicons/bulb.png";
import peace from "./favicons/peace.png";
import kiss from "./favicons/kiss.png";
import tada from "./favicons/tada.png";

const icons = [favicon, tada, faviconTwo, star, bulb, peace, kiss];
class SEO extends React.Component {
  state = {
    count: 0,
    tabChanged: false
  };
  componentDidMount() {
    this.initGA();
    window.onblur = function() {
      document.title = "Vi saknar dig redan 😚";
    };

    window.onfocus = () => {
      document.title = this.props.title;
    };

    setInterval(() => {
      let count;

      if (this.state.count + 1 >= icons.length) {
        count = 0;
      } else {
        count = this.state.count + 1;
      }

      this.setState({
        count
      });
    }, 3000);
  }

  initGA() {
    window.dataLayer = window.dataLayer || [];
    function gtag() {
      window.dataLayer.push(arguments);
    }
    gtag("js", new Date());

    gtag("config", "UA-43885727-5");
  }

  render() {
    const { meta, title, description, image } = this.props;
    const icon = icons[this.state.count];

    return (
      <Helmet>
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>{title}</title>
        <link rel="shortcut icon" type="image/png" href={icon} />

        <meta property="og:url" content="http://etablera.co" />
        <meta name="description" content={description} />

        <meta name="twitter:card" content="summary" />
        <meta name="twitter:title" content={title} />
        <meta name="twitter:description" content={description} />
        <meta name="twitter:creator" content="@jontgus" />
        <meta name="twitter:image" content={image} />

        <meta property="og:title" content={title} />
        <meta property="og:type" content="website" />

        <meta property="og:image" content={image} />
        <meta property="og:description" content={description} />
        <meta property="og:site_name" content="Etablera" />
        <meta name="image" content={image} />
        <html lang="sv" />

        <script
          async
          src="https://www.googletagmanager.com/gtag/js?id=UA-43885727-5"
        />
      </Helmet>
    );
  }
}

export default SEO;
