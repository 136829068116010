import React from 'react';
import VimeoItem from './VimeoItem';

class VimeoGrid extends React.Component {
  render() {
    return (
      <div className="vimeo-grid">
        <div className="vimeo-grid-inner">
          <h2>VÅRA TIDIGARE PROJEKT</h2>
          <p>Testkoncept som ni säkert tycker är sådär – men har det nått?</p>
          <div className="vimeo-grid-videos">
            <VimeoItem vnum="288326765" />
            <VimeoItem vnum="222099693" />
            <VimeoItem vnum="256201413" />
            <VimeoItem vnum="230635208" />
            <VimeoItem vnum="230635258" />
            <VimeoItem vnum="230635282" />
            <VimeoItem vnum="257343520" />
            <VimeoItem vnum="258128615" />
            <VimeoItem vnum="230635263" />
            <VimeoItem vnum="242355033" />
            <VimeoItem vnum="290373928" />
            <VimeoItem vnum="281335361" />
            <VimeoItem vnum="279547713" />
            <VimeoItem vnum="274276551" />
            <VimeoItem vnum="239355580" />
            <VimeoItem vnum="237900493" />
            <VimeoItem vnum="230627934" />
            <VimeoItem vnum="179582668" />
            <VimeoItem vnum="290794549" />
            <VimeoItem vnum="204371674" />
            <VimeoItem vnum="286603896" />
            <VimeoItem vnum="286604042" />
            <VimeoItem vnum="286603567" />
            <VimeoItem vnum="209106537" />
            <VimeoItem vnum="286835426" />
          </div>
        </div>
      </div>
    );
  }
}

export default VimeoGrid;
