import React from "react";
import Img from "gatsby-image";
// import scrollMonitor from 'scrollmonitor';
// import anime from 'animejs';

class Footer extends React.Component {
  // componentDidMount() {
  //   const animation = anime({
  //     targets: this.title,
  //     opacity: [0, 1],
  //     translateY: [15, 0],
  //     autoplay: false
  //   });

  //   const watcher = scrollMonitor.create(this.title, -100);

  //   watcher.enterViewport(() => {
  //     animation.play();
  //     watcher.destroy();
  //   });
  // }

  render() {
    return (
      <footer className="footer" ref={ref => (this.container = ref)} id="vi">
        <Img
          critical
          sizes={this.props.image.childImageSharp.sizes}
          style={{
            position: "absolute",
            left: 0,
            top: 0,
            width: "100%",
            height: "100%"
          }}
        />

        {/* <div className="footer-bottom">
          <h2 ref={ref => (this.title = ref)}>Vi hörs!</h2>
        </div> */}
      </footer>
    );
  }
}

export default Footer;
