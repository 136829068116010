import React from 'react';
import image from '../images/lorre.jpg';
import styles from './lorre.css';

class Loader extends React.Component {
  componentDidMount() {
    setTimeout(() => {
      this.props.onLoaded && this.props.onLoaded();
    }, 820);
  }

  render() {
    return (
      <div className="loader">
        <div ref={ref => (this.loader = ref)} className="loader-inner" />;
      </div>
    );
  }
}

export default Loader;
