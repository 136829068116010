import React from 'react';

export default ComposedComponet => {
  class Page extends React.Component {
    state = {
      renderAll: false
    };

    setAppLoaded = () => {
      setTimeout(() => {
        this.setState({ renderAll: true });
      }, 200);
    };

    componentDidMount() {
      if (typeof document !== 'undefined') {
        (function(d) {
          var x = d.createElement('link');
          var y = d.getElementsByTagName('script')[0];
          x.rel = 'stylesheet';
          x.href =
            'https://fonts.googleapis.com/css?family=Poppins:400,700,800:font-display-fallback|Roboto:300,400';
          y.parentNode.insertBefore(x, y);
        })(document);
      }
    }

    render() {
      return (
        <ComposedComponet
          setAppLoaded={this.setAppLoaded}
          renderAll={this.state.renderAll}
          {...this.props}
        />
      );
    }
  }

  return Page;
};
