import React from "react";
import anime from "animejs";
import cn from "classnames";
import Typed from "typed.js";
import isMobile from "ismobilejs";
import Loader from "./Loader";
import { IdleQueue } from "../idlize/IdleQueue";

class HeroText extends React.Component {
  state = {
    lettersDone: false,
    titleSmall: false,
    notLoaded: true
  };

  constructor(props) {
    super(props);

    this.queue = new IdleQueue();
  }

  componentDidMount() {
    this.queue.pushTask(() => this.animate());
  }

  animateHeadline = () => {
    anime({
      targets: ".title",
      scale: [1, 0.65]
    });
  };

  onLoaderDone = () => {
    this.setState({
      notLoaded: false
    });
  };

  componentDidUpdate(_, prevState) {
    if (prevState.notLoaded && !this.state.notLoaded) {
      this.queue.pushTask(() => {
        this.anim.play();
      });
    }
  }

  animate = () => {
    this.anim = anime.timeline({ loop: false, autoplay: false }).add({
      targets: ".title-letter",
      scale: !isMobile.any ? [1.5, 1] : 1,
      opacity: [0, 1],
      easing: "easeOutExpo",
      duration: 950,
      delay: function(el, i) {
        return 70 * i;
      }
    });

    if (!isMobile.any) {
      this.anim.add({
        targets: ".hero-dictionary",
        translateY: [-30, -50],
        opacity: [0, 1],
        easing: "easeOutExpo",
        autoplay: false,
        offset: "-=300"
      });
    }

    this.anim
      .add({
        targets: ".hero-content",
        left: ["50%", "10%"],
        translateY: ["-50%", "-50%"],
        translateX: ["-50%", 0],
        offset: "-=450",
        begin: () => {
          this.animateHeadline();
        }
      })
      .add({
        targets: ".hero-dictionary",
        translateY: [-50, 120],
        easing: "easeOutExpo",
        offset: "-=350",
        begin: () => {
          this.animateTexts();
        },
        complete: () => {
          this.props.onLoaded();
        }
      });
  };

  animateTexts = () => {
    this.setState({
      lettersDone: true
    });
    const options = {
      strings: [
        "kommunikation som syns",
        "filmer som väcker känslor",
        "digital närvaro"
      ],
      typeSpeed: 40,
      loop: true
    };

    new Typed("#typed", options);
  };

  dictionary() {
    return (
      <div className="hero-dictionary">
        <div>
          <b>[e-tabl-era]</b>
        </div>
        <div>verb ~de ~t </div>
        <div>
          <li>1. upprätta, grunda</li>
          <li>
            2. <i>(om organisation eller kommunikationsväg) </i> finna fotfäste;
            bli allmänt känd och accepterad
          </li>
        </div>
      </div>
    );
  }
  render() {
    const title = "etablera";
    const letters = title.split("").map((letter, i) => (
      <span
        className="title-letter"
        // style={{ transitionDelay: `${70 * i}ms` }}
        key={i}
      >
        {letter}
      </span>
    ));

    return (
      <div>
        {this.state.notLoaded && <Loader onLoaded={this.onLoaderDone} />}

        <div className="hero-content">
          <div className="hero-content-inner">
            <div className={cn("hero-main", { done: this.state.lettersDone })}>
              {<span className="hero-main-start">Vi hjälper er</span>}{" "}
              <h1 ref={ref => (this.title = ref)} className="title">
                {letters}
              </h1>{" "}
              {
                <div className="hero-typed">
                  <span id="typed" />
                </div>
              }
            </div>
            {this.dictionary()}
          </div>
        </div>
      </div>
    );
  }
}

export default HeroText;
