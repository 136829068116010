import React from "react";
import scrollMonitor from "scrollmonitor";
import cn from "classnames";
import isMobile from "ismobilejs";

const allMessages = [
  {
    name: "Valdemar, Miltton Labs",
    image: "👨🏻‍💻",
    messages: ["SNYYYGGT", "Bra jobbat, tajt klippt!"]
  },
  {
    name: "Hedwig, OpenHack",
    image: "💁🏼‍",
    messages: ["Jaa jättesnygg video verkligen! Blir peppad av att se den"]
  },
  {
    name: "Linn, LAVA",
    image: "🙋🏻‍",
    messages: ["ÅÅÅÅÅH!", "Hahahaha! Så jäkla bra Oscar!"]
  },
  {
    name: "Elsa, elsalandberg.com",
    image: "👩🏼‍💻",
    messages: ["Ruskigt fet video från Almedalen!!!", "Såg precis på linkan"]
  },
  {
    name: "Amanda, födelsedagsevent för hennes mamma",
    image: "👱🏼‍♀️",
    messages: [
      "Vi är så nöjda med stämningen och mamma tyckte att ni gjorde ett superjobb 👏🏼"
    ]
  },
  {
    name: "Agaton, Anders Wall 2017",
    image: "🕵🏻‍♂️",
    messages: ["Och ännu en gång, verkligen ett snyggt jobb! "]
  },
  {
    name: "Oskar, Red Bull",
    image: "👨🏼‍🔬",
    messages: ["Skön film du klippte ihop! 😍"]
  },
  {
    name: "Ann, Hack for Sweden",
    image: "💁🏻‍♀️",
    messages: ["Galet bra 🤩🤩", "Love it", "Precis så jag tänkte mig!!"]
  },
  {
    name: "Elsa, elsalandberg.com",
    image: "👩🏼‍💻",
    messages: [
      "OMG Erik din aftermovie är helt seriöst ett mästerverk!! Gud vad DUKTIG du är 🌟🌟🌟"
    ]
  },
  {
    name: "Måns, EWB Sweden",
    image: "👱🏼‍♂️",
    messages: [
      "Jag satt just och kollade igenom filmen tillsammans med Clara, det ser asbra ut 😃",
      "Så jävla grymt jobbat!"
    ]
  },
  {
    name: "Mats, Studentbokhandeln",
    image: "👨🏻",
    messages: ["Vad fint! Det blir ju kanon!", "Tusen tack för allt jobb!"]
  },
  {
    name: "Ebba, Datatjej",
    image: "👱🏼‍♀️",
    messages: [
      "Hello fotofavoriter! ❤️ tack för att ni var med och förevigade konferensen!"
    ]
  },
  {
    name: "Amanda, födelsedagsevent för hennes mamma",
    image: "👱🏼‍♀️",
    messages: [
      "Vilket jobb och jag är såååå tacksam och lycklig som har detta minne från kvällen! Ni har verkligen skapat något som kommer att följa med i flera flera år! Satan vad ni är bra 💗"
    ]
  },
  {
    name: "Ann, Hack for Sweden",
    image: "💁🏻‍♀️",
    messages: [
      "Hej Erik! Vilket fantastiskt jobb ni har gjort! Helhetsintrycket är oerhört proffsigt"
    ]
  },
  {
    name: "Valdemar, Miltton Labs",
    image: "👨🏻‍💻",
    messages: [
      "Snyggt! Jävligt bra jobbat med både bild och koncept, det känns sammanhängande och välproducerat!"
    ]
  },
  {
    name: "Anja, SSES",
    image: "🦹🏼‍♀️",
    messages: ["Hej Erik! Vad snyggt allt blev!"]
  },
  {
    name: "Annie Lindmark, Vinnova",
    image: "👩🏼‍💻",
    messages: ["Kul, snyggt jobbat av er"]
  },
  {
    name: "Åsa, Internetstiftelsen",
    image: "👩🏽‍⚕️",
    messages: ["Så bra det blev"]
  },
  {
    name: "Andreas, Polisen",
    image: "👮🏼‍♂️",
    messages: ["Ni har gjort grymt snygga filmer"]
  },
  {
    name: "Mårten, Ingenjörer utan gränser",
    image: "👨🏻‍🎓",
    messages: ["Extremt jäkla snyggt! Mäktigt"]
  },
  {
    name: "Sonja, KTH",
    image: "🙋🏻‍♀️",
    messages: ["Grymma ni är!”"]
  },
  {
    name: "Sofie, Tillväxtverket",
    image: "👩🏼‍🔧",
    messages: ["Så snyggt gjort och tänkt!"]
  }
];
function shuffleArray(arr) {
  const array = [...arr];
  for (let i = array.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [array[i], array[j]] = [array[j], array[i]]; // eslint-disable-line no-param-reassign
  }

  return array;
}

const messages = isMobile.any
  ? shuffleArray(allMessages).slice(0, 10)
  : allMessages;

class Feedback extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      visible: 0
    };
  }

  componentDidMount() {
    const watcher = scrollMonitor.create(
      document.querySelector(".feedback"),
      -400
    );

    const Isotope = require("isotope-layout");
    const iso = new Isotope(this.container, {
      itemSelector: ".message"
    });

    watcher.enterViewport(() => {
      this.showMessages();

      watcher.destroy();
    });
  }

  showMessages() {
    const totalCount = messages.length;
    const int = setInterval(() => {
      if (this.state.visible > totalCount) {
        clearInterval(int);
      } else {
        this.setState({
          visible: this.state.visible + 1
        });
      }
    }, 100);

    const timeout = totalCount * 100 + (300 + 100);

    setTimeout(() => {
      this.setState({ feedback: true });
    }, timeout);
  }

  message = (item, i) => {
    const visibleClass = i < this.state.visible ? "show" : "";
    const { image, name, messages } = item;
    return (
      <div key={i} className={"message " + visibleClass}>
        <div className="message-img">
          <span>{image}</span>
        </div>
        <div>
          <div className="message-name">{name}</div>
          <div className="message-content">
            {messages.map((m, key) => (
              <div
                className={cn("message-item", {
                  "message-item-last": key == messages.length - 1
                })}
                key={key}
              >
                {m}
              </div>
            ))}
          </div>
        </div>
      </div>
    );
  };

  response() {
    const itemClass = cn("message", { show: this.state.feedback });

    return (
      <div className={itemClass}>
        <div>
          <div className="message-name">Etablera.co</div>
          <div className="message-content">
            <div
              className={cn(
                "message-item",
                "message-item-last",
                "message-response"
              )}
            >
              {"Vi gillar er 💗💗💗"}
            </div>
          </div>
        </div>
        <div className="message-img message-img-response">
          <span>👨‍👨‍👦‍</span>
        </div>
      </div>
    );
  }

  render() {
    return (
      <div className="feedback" id="friends">
        <div className="feedback-inner">
          <h2>Våra kompisar verkar gilla det vi gör.</h2>
          <div className="feedback-quotes" ref={ref => (this.container = ref)}>
            {messages.map(this.message)}
          </div>
          <div className={cn("feedback-response")}>{this.response()}</div>
          <p className="feedback-comment">
            <span className="feedback-asterix">*</span> 100% ofiltrerad respons
            från riktiga människor.
          </p>
        </div>
      </div>
    );
  }
}

export default Feedback;
