import React from "react";

class Footer extends React.Component {
  componentDidMount() {}
  render() {
    return (
      <div className="info-footer" name="info-footer">
        <div className="info-footer-container">
          <div className="info-footer-item" id="info-footer-header">
            <h1>ETABLERA</h1>
            <p
              className="tooltip--triangle"
              data-tooltip="Men vi älskar att resa 🚄"
            >
              <strong id="info-footer-header-undertitle">
                STOCKHOLM-UPPSALA
              </strong>
            </p>
          </div>
          <div className="info-footer-item" id="info-footer-kontakt">
            <h2>KONTAKT</h2>
            <p>
              <a href="tel:+6586805812">+65 8680 5812</a>
              <br />
              <a href="mailto:hej@etablera.co">hej@etablera.co</a>
            </p>
          </div>

          <div className="info-footer-item">
            <h2>TEAM</h2>
            <p>
              <a href="https://www.instagram.com/eriks/">Erik</a>
              <br />
              <a href="https://www.instagram.com/jontgus/">Jonte</a>
              <br />
              <a href="https://www.instagram.com/oscarmorke/">Oscar</a>
              <br />
              <a href="mailto:hej@etablera.co">Du? 📲</a>
            </p>
          </div>
        </div>
        <div className="icon-container">
          <ul className="soc">
            <li>
              <a
                href="https://vimeo.com/tjena"
                className="icon-1 vimeo"
                title="Vimeo"
              >
                <svg viewBox="0 0 512 512">
                  <path d="M420.1 197.9c-1.5 33.6-25 79.5-70.3 137.8 -46.9 60.9-86.5 91.4-118.9 91.4 -20.1 0-37.1-18.5-51-55.6 -9.3-34-18.5-68-27.8-102 -10.3-37.1-21.4-55.7-33.2-55.7 -2.6 0-11.6 5.4-27 16.2L75.7 209.1c17-14.9 33.8-29.9 50.3-44.9 22.7-19.6 39.7-29.9 51.1-31 26.8-2.6 43.3 15.8 49.5 55 6.7 42.4 11.3 68.7 13.9 79 7.7 35.1 16.2 52.7 25.5 52.7 7.2 0 18-11.4 32.5-34.2 14.4-22.8 22.2-40.1 23.2-52.1 2.1-19.7-5.7-29.5-23.2-29.5 -8.3 0-16.8 1.9-25.5 5.7 16.9-55.5 49.3-82.4 97.1-80.9C405.5 130 422.2 153 420.1 197.9z" />
                </svg>
              </a>
            </li>

            <li>
              <a href="#info-footer" className="icon-2 youtube" title="YouTube">
                <svg viewBox="0 0 512 512">
                  <path d="M422.6 193.6c-5.3-45.3-23.3-51.6-59-54 -50.8-3.5-164.3-3.5-215.1 0 -35.7 2.4-53.7 8.7-59 54 -4 33.6-4 91.1 0 124.8 5.3 45.3 23.3 51.6 59 54 50.9 3.5 164.3 3.5 215.1 0 35.7-2.4 53.7-8.7 59-54C426.6 284.8 426.6 227.3 422.6 193.6zM222.2 303.4v-94.6l90.7 47.3L222.2 303.4z" />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="http://flickr.com/eriknson"
                className="icon-3 flickr"
                title="Flickr"
              >
                <svg viewBox="0 0 512 512">
                  <path d="M344.5 156.9c-38.7 0-72.1 22.1-88.5 54.4 -16.4-32.3-49.8-54.4-88.5-54.4 -54.8 0-99.1 44.4-99.1 99.1 0 54.8 44.4 99.1 99.1 99.1 38.6 0 72.1-22.1 88.5-54.4 16.4 32.3 49.8 54.4 88.5 54.4 54.8 0 99.1-44.4 99.1-99.1C443.6 201.2 399.2 156.9 344.5 156.9zM344.5 328.7c-40.1 0-72.7-32.6-72.7-72.7s32.6-72.7 72.7-72.7 72.7 32.6 72.7 72.7C417.2 296.1 384.6 328.7 344.5 328.7z" />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="https://github.com/jonathangus"
                className="icon-4 github"
                title="GitHub"
              >
                <svg viewBox="0 0 512 512">
                  <path d="M256 70.7c-102.6 0-185.9 83.2-185.9 185.9 0 82.1 53.3 151.8 127.1 176.4 9.3 1.7 12.3-4 12.3-8.9V389.4c-51.7 11.3-62.5-21.9-62.5-21.9 -8.4-21.5-20.6-27.2-20.6-27.2 -16.9-11.5 1.3-11.3 1.3-11.3 18.7 1.3 28.5 19.2 28.5 19.2 16.6 28.4 43.5 20.2 54.1 15.4 1.7-12 6.5-20.2 11.8-24.9 -41.3-4.7-84.7-20.6-84.7-91.9 0-20.3 7.3-36.9 19.2-49.9 -1.9-4.7-8.3-23.6 1.8-49.2 0 0 15.6-5 51.1 19.1 14.8-4.1 30.7-6.2 46.5-6.3 15.8 0.1 31.7 2.1 46.6 6.3 35.5-24 51.1-19.1 51.1-19.1 10.1 25.6 3.8 44.5 1.8 49.2 11.9 13 19.1 29.6 19.1 49.9 0 71.4-43.5 87.1-84.9 91.7 6.7 5.8 12.8 17.1 12.8 34.4 0 24.9 0 44.9 0 51 0 4.9 3 10.7 12.4 8.9 73.8-24.6 127-94.3 127-176.4C441.9 153.9 358.6 70.7 256 70.7z" />
                </svg>
              </a>
            </li>

            <li>
              <a
                href="https://www.instagram.com/oscarmorke/"
                className="icon-5 instagram"
                title="Instagram"
              >
                <svg viewBox="0 0 512 512">
                  <path d="M256 109.3c47.8 0 53.4 0.2 72.3 1 17.4 0.8 26.9 3.7 33.2 6.2 8.4 3.2 14.3 7.1 20.6 13.4 6.3 6.3 10.1 12.2 13.4 20.6 2.5 6.3 5.4 15.8 6.2 33.2 0.9 18.9 1 24.5 1 72.3s-0.2 53.4-1 72.3c-0.8 17.4-3.7 26.9-6.2 33.2 -3.2 8.4-7.1 14.3-13.4 20.6 -6.3 6.3-12.2 10.1-20.6 13.4 -6.3 2.5-15.8 5.4-33.2 6.2 -18.9 0.9-24.5 1-72.3 1s-53.4-0.2-72.3-1c-17.4-0.8-26.9-3.7-33.2-6.2 -8.4-3.2-14.3-7.1-20.6-13.4 -6.3-6.3-10.1-12.2-13.4-20.6 -2.5-6.3-5.4-15.8-6.2-33.2 -0.9-18.9-1-24.5-1-72.3s0.2-53.4 1-72.3c0.8-17.4 3.7-26.9 6.2-33.2 3.2-8.4 7.1-14.3 13.4-20.6 6.3-6.3 12.2-10.1 20.6-13.4 6.3-2.5 15.8-5.4 33.2-6.2C202.6 109.5 208.2 109.3 256 109.3M256 77.1c-48.6 0-54.7 0.2-73.8 1.1 -19 0.9-32.1 3.9-43.4 8.3 -11.8 4.6-21.7 10.7-31.7 20.6 -9.9 9.9-16.1 19.9-20.6 31.7 -4.4 11.4-7.4 24.4-8.3 43.4 -0.9 19.1-1.1 25.2-1.1 73.8 0 48.6 0.2 54.7 1.1 73.8 0.9 19 3.9 32.1 8.3 43.4 4.6 11.8 10.7 21.7 20.6 31.7 9.9 9.9 19.9 16.1 31.7 20.6 11.4 4.4 24.4 7.4 43.4 8.3 19.1 0.9 25.2 1.1 73.8 1.1s54.7-0.2 73.8-1.1c19-0.9 32.1-3.9 43.4-8.3 11.8-4.6 21.7-10.7 31.7-20.6 9.9-9.9 16.1-19.9 20.6-31.7 4.4-11.4 7.4-24.4 8.3-43.4 0.9-19.1 1.1-25.2 1.1-73.8s-0.2-54.7-1.1-73.8c-0.9-19-3.9-32.1-8.3-43.4 -4.6-11.8-10.7-21.7-20.6-31.7 -9.9-9.9-19.9-16.1-31.7-20.6 -11.4-4.4-24.4-7.4-43.4-8.3C310.7 77.3 304.6 77.1 256 77.1L256 77.1z" />
                  <path d="M256 164.1c-50.7 0-91.9 41.1-91.9 91.9s41.1 91.9 91.9 91.9 91.9-41.1 91.9-91.9S306.7 164.1 256 164.1zM256 315.6c-32.9 0-59.6-26.7-59.6-59.6s26.7-59.6 59.6-59.6 59.6 26.7 59.6 59.6S288.9 315.6 256 315.6z" />
                  <circle cx="351.5" cy="160.5" r="21.5" />
                </svg>
              </a>
            </li>
          </ul>
          <p>&copy; 2015-2018 Etablera. All rights reserved.</p>
        </div>
      </div>
    );
  }
}

export default Footer;
