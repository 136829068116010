import React from "react";
import isMobile from "ismobilejs";
import Hls from "hls.js";
import { IdleQueue } from "../idlize/IdleQueue";

const mp4Source =
  "https://player.vimeo.com/external/308530109.sd.mp4?s=abb440011d685f2583a2131f8bcf4b69415d35ac&profile_id=164";

class HeroVideo extends React.Component {
  constructor(props) {
    super(props);

    this.queue = new IdleQueue();
  }
  componentDidUpdate(prevProps) {
    if (!prevProps.textAnimated && this.props.textAnimated) {
      if (this.loadMp4) {
        this.queue.pushTask(() => {
          this.video.load();
        });
        this.queue.pushTask(() => {
          this.video.play();
        });

        this.queue.pushTask(() => {
          this.addListeners();
        });
      }
    }
  }

  addListeners = () => {
    const onCanPlay = () => {
      const time = isMobile.any ? 1600 : 1;
      setTimeout(() => {
        this.queue.pushTask(() => {
          this.props.onVideoLoaded();
          this.video.removeEventListener("canplaythrough", onCanPlay, false);
          this.video.removeEventListener("load", onCanPlay, false);
        });
      }, time);
    };

    this.video.addEventListener("canplaythrough", onCanPlay, false);
    this.video.addEventListener("load", onCanPlay, false); //add load event as well to avoid errors, sometimes 'canplaythrough' won't dispatch.
  };

  componentDidMount() {
    const video = document.getElementById("video");
    const { source } = this.props;
    if (Hls.isSupported()) {
      const source =
        "https://player.vimeo.com/external/308530109.m3u8?s=bf9cc1c01a4fc79d6f1ba1a2d9a0c1b6a36e74a3";
      const hls = new Hls();
      hls.loadSource(source);
      hls.attachMedia(video);

      hls.on(Hls.Events.MANIFEST_PARSED, (event, data) => {
        video.play();
        this.props.onVideoLoaded();
      });
    } else {
      this.loadMp4 = true;
      const { source } = this.props;
      this.queue.pushTask(() => {
        this.video.src = mp4Source;
      });
    }
  }

  render() {
    return (
      <React.Fragment>
        <video
          ref={ref => (this.video = ref)}
          preload="auto"
          loop
          playsInline
          muted
          id="video"
        />
      </React.Fragment>
    );
  }
}

export default HeroVideo;
